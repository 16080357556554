import React, { FC } from "react"
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
// import "swiper/components/navigation/navigation.css"
// import "swiper/components/pagination/pagination.css"
// import "swiper/components/scrollbar/scrollbar.css"

SwiperCore.use([ Navigation, Pagination, Scrollbar, A11y ])

const Slider: FC<Swiper> = ({
  children,
  ...props
}) => (
  <Swiper {...props}>
    {React.Children.map(children, (slide) => (
      <SwiperSlide>
        {React.cloneElement(slide as any)}
      </SwiperSlide>
    ))}
  </Swiper>
)

Slider.defaultProps = {
  navigation: true
}

export default Slider
